<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row">
                <div class="row-xs-2">
                    <h1 class="h2 margin-bottom-30" v-if="siteDetail">{{siteDetail.name}}</h1>
                </div>
            </div>
            <div>
                <table class="table table-hover">
                    <tbody id="sites-list">
                        <SiteHoursRow v-for='siteHour in siteHours' :siteHour="siteHour" v-bind:key ='siteHour.id'/>
                    </tbody>
                </table>
            </div>
        </div>
    </ContainerWithMenu>
</template>

<script>
import ContainerWithMenu from "../components/container/ContainerWithMenu";
import {getSiteDetail} from "../api"
import baseMixin from "../common/baseMixin"
import SiteHoursRow from "../components/Site/SiteHoursRow";

export default {
    title: "Orari dei Siti",
    name: "site_hours",
    components: {SiteHoursRow, ContainerWithMenu},
    mixins: [baseMixin],
    data: function() {
        return {
            loading: false,
            site_id: null,
            siteHours: [],
            siteDetail: null
        }
    },
    mounted() {
        this.site_id = this.$route.params.site_id;
        this.getSiteDetail(this.site_id);
    },
    methods: {
        async getSiteDetail(id) {
            this.loading = true;
            try {
                this.siteDetail = await getSiteDetail(id);
                this.siteHours = this.siteDetail.opening_hours
                this.loading = false;

            } catch (e) {
                this.loading = false;
                console.log(e)
            }
        }
    }
};
</script>
