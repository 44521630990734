<template>
    <tr>
        <td>{{StringsHelper.getDay(siteHour.day)}}</td>


        <td>Orario di Apertura: <input class="form-control" type="time" id="open-hour" :value="siteHour.open_hour" @change="changedOpenHour"/></td>
        <td>Orario di Chiusura: <input class="form-control" type="time" id="close-hour" :value="siteHour.close_hour" @change="changedCloseHour"/></td>
        <td>
            <span v-if="siteHour.is_open">Aperto</span>
            <span v-else>Chiuso</span>
            <ToggleSwitch :id='siteHour.day' :isOpened="siteHour.is_open" @switched="switched"/>
        </td>
    </tr>
</template>

<script>

import baseMixin from '@/common/baseMixin'
import ToggleSwitch from "../common/ToggleSwitch";
import {updateSiteHours} from "../../api";
import {TIMEZONE_DEFAULT} from "../../common/constants";

export default {
    name: "SiteHoursRow",
    mixins: [baseMixin],
    components: {ToggleSwitch},
    data: function() {
        return {
            TIMEZONE_DEFAULT: TIMEZONE_DEFAULT,
            loading: false,
            site_id: null
        }
    },
    mounted() {

    },
    props: {
        siteHour: {
            type: Object,
            required: true
        }
    },
    methods:{
        switched(){
            this.siteHour.is_open = !this.siteHour.is_open
            this.updateSiteHours(this.siteHour)
        },
        changedCloseHour(e){
            this.siteHour.close_hour = e.target.value
            this.updateSiteHours(this.siteHour)
        },
        changedOpenHour(e){
            this.siteHour.open_hour = e.target.value
            this.updateSiteHours(this.siteHour)
        },
        async updateSiteHours(siteHours) {
            this.loading = true;
            try {
                await updateSiteHours(siteHours.site,siteHours.id,siteHours);
                this.loading = false;
            } catch (e) {
                this.loading = false;
                console.log(e)
            }
        },
    }
}
</script>