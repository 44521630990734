<template>
    <div class="material-switch pull-right">
        <input :id="id" type="checkbox" @click="switched" :value="isOpened" :checked="isOpened"/>
        <label :for="id" class="label-primary"></label>
    </div>
</template>

<script>
    export default {
        props: {
            id: {
                type: Number,
                required: true
            },
            isOpened:{
                type: Boolean,
                required: true,
            }
        },

        name: "ToggleSwitch",
        methods: {
            switched() {
                this.$emit('switched');
            },
        }
    };
</script>
